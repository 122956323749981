import { NgModule } from '@angular/core';
import {
  APOLLO_NAMED_OPTIONS,
  APOLLO_OPTIONS,
  ApolloModule,
} from 'apollo-angular';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { RetryLink } from '@apollo/client/link/retry';

import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { environment } from '../environments/environment';
import { AuthService } from '@gyselroth/angular-auth';
import { HelperService } from './shared/helper/services/helper.service';
import { possibleTypes } from './generated/possibleTypes';
import { organizationConfig } from './shared/organization/organization.config';

const uri = environment.graphQlUri;
const grAPI = environment.grApiUrl;

const organization = organizationConfig.find(organization =>
  organization.urls.includes(window.location.origin)
);

export function m2oAPIApollo(
  httpLink: HttpLink,
  authService: AuthService,
  helperService: HelperService
): ApolloClientOptions<any> {
  const auth = setContext(async () => {
    const contextToSet: any = {
      headers: {},
    };
    let isLoggedIn = false;

    authService.loggedIn$.subscribe(event => (isLoggedIn = event));

    if (isLoggedIn) {
      // no request will be sent until there is a token available
      const token = await authService.getAccessToken();

      contextToSet.headers.Authorization = `Bearer ${token}`;
    }

    if (organization) {
      contextToSet.headers['Organization-Short-Name'] = organization.shortName;
    }

    return contextToSet;
  });

  const retryLink = new RetryLink();

  return {
    link: ApolloLink.from([auth, retryLink, httpLink.create({ uri })]),
    cache: new InMemoryCache({
      possibleTypes,
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  };
}

export function grAPIApollo(
  httpLink: HttpLink,
  authService: AuthService
): Record<string, ApolloClientOptions<any>> {
  const auth = setContext(async (_, context) => {
    const contextToSet: any = {
      headers: {},
    };

    contextToSet.headers['Client-Name'] = 'move2one';

    if (!context.skipAuth) {
      // no request will be sent until there is a token available
      const token = await authService.getAccessToken();
      contextToSet.headers.Authorization = `Bearer ${token}`;
    }

    return contextToSet;
  });

  const retryLink = new RetryLink();

  return {
    grAPI: {
      link: ApolloLink.from([auth, retryLink, httpLink.create({ uri: grAPI })]),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'all',
        },
      },
      name: 'grAPI',
    },
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: m2oAPIApollo,
      deps: [HttpLink, AuthService, HelperService],
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: grAPIApollo,
      deps: [HttpLink, AuthService],
    },
  ],
})
export class GraphQLModule {}
