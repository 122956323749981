export interface OrganizationConfig {
  shortName: string;
  urls: string[];
  loggedInRedirectToUrl: string;
  mailEnding: string[];
}

export const organizationConfig: OrganizationConfig[] = [
  {
    shortName: 'gr',
    urls: ['http://gr.localhost:4200', 'https://gr.move2one.gyselroth.net'],
    loggedInRedirectToUrl: 'mails',
    mailEnding: ['@gyselroth.com'],
  },
  {
    shortName: 'kst',
    urls: [
      'http://kst.localhost:4200',
      'https://kst.move2one.gyselroth.net',
      'https://move2one.gyselroth.net',
      'https://movetoone.ksstadelhofen.ch',
    ],
    loggedInRedirectToUrl: 'news',
    mailEnding: ['@ksstadelhofen.ch'],
  },
];
